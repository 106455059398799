import React, { useEffect, useState } from "react";
import "../../assets/css/style.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Offcanvas } from 'react-bootstrap';
import { BsBell } from "react-icons/bs";
import coutryList from '../../Helper/CountryList.json'

const Header = () => {
  // const handleCaptureClick = async () => {
  //   const canvas = await html2canvas(document.body);
  //   const dataURL = canvas.toDataURL("uploads/MT5-android.pdf");
  //   downloadjs(dataURL, "download.png", "uploads/MT5-android.pdf");
  // };
  const navigate = useNavigate();
  const currency = useNavigate();

  const handleRoute = (route) => {
    navigate(`/${route}`);

    setTimeout(() => {
      window.scroll({
        top: 0,

        behavior: "smooth",
      });
    }, 100);
  };
  const [showdata2, setShowdata2] = useState(false)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [inputFormat, setInputFormat] = useState('en');

  const currencypage = (data) => {
    currency(`/${data}`);
    setTimeout(() => {
      window.scroll({
        top: 0,

        behavior: "smooth",
      });
    }, 100);
  };

  useEffect(() => {
    const currentLanguage = localStorage.getItem('language');
    if (!!currentLanguage) {
      setInputFormat(currentLanguage);
    }
    else {
      localStorage.setItem('language', 'en');
    }
  }, [])

  // const handleLang = (lang) => {
  //   setInputFormat(lang);
  //   localStorage.setItem('language', lang)
  //   var languageSelect = document.querySelector("select.goog-te-combo");
  //   languageSelect.value = lang;
  //   languageSelect.dispatchEvent(new Event("change"));
  // }

  // const googleTranslateElementInit = () => {
  //   console.log( localStorage.getItem('language'))
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: localStorage.getItem('language'),
  //       autoDisplay: true

  //     },
  //     "google_translate_element"
  //   );
  // };

  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   addScript.setAttribute("style", "visibility:hidden !important",);
  //   addScript.setAttribute("type", "text/javascript");
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;

  // }, [inputFormat]);

  const handlelangChange = (e) => {
    // setTimeout(() => {

    //   window.location.reload();
    // }, 2000)
  };


  return (
    <div>
      <div id="google_translate_element" style={{ visibility: "hidden" }}></div>
      <div className="millivaheader">
        <nav className="navbar navbar-expand-xl navbar-light bg-light d-flex align-items-center justify-content-between">
          <div className="container">

            <button
              className="btn btn navbar-toggler border-3 px-2 "
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
            >


              {/* <svg
                height="25px"
                id="Layer_1"
                version="1.1"
                viewBox="0 0 32 32"
                width="32px"
                xmlSpace="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                style={{ color: "rgb(61, 61, 61)" }}
              >
                <path
                  d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2 s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2 S29.104,22,28,22z"
                  fill="#3d3d3d"
                />
              </svg> */}
              <svg width="30" height="30" viewBox="0 0 24 24" fill="none">
                <path d="M3 12H15M3 6H21M3 18H21" stroke="#f18c20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </button>
            <div className="pulse1 d-lg-none d-md-none">
              <i class="fa fa-bell"></i>
            </div>
            <Link to="/" className="navbar-brand d-flex align-items-center ">
              <img
                src={require("../../assets/images/home/milliva-logo-text.png")}
                style={{ width: "150px", height: "100%" }}
                alt="logo"
                className="img-fluid mx-2"
              />
            </Link>

            <Link to="https://crm.milliva.com/login" className="signinicoimg">
              <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 8a1 1 0 0 0 1-1V5.923c0-.459.022-.57.082-.684a.364.364 0 0 1 .157-.157c.113-.06.225-.082.684-.082h10.154c.459 0 .57.022.684.082.07.038.12.087.157.157.06.113.082.225.082.684v12.154c0 .459-.022.57-.082.684a.363.363 0 0 1-.157.157c-.113.06-.225.082-.684.082H7.923c-.459 0-.57-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684V17a1 1 0 1 0-2 0v1.077c0 .76.082 1.185.319 1.627.223.419.558.753.977.977.442.237.866.319 1.627.319h10.154c.76 0 1.185-.082 1.627-.319.419-.224.753-.558.977-.977.237-.442.319-.866.319-1.627V5.923c0-.76-.082-1.185-.319-1.627a2.363 2.363 0 0 0-.977-.977C19.262 3.082 18.838 3 18.077 3H7.923c-.76 0-1.185.082-1.627.319a2.363 2.363 0 0 0-.978.977C5.083 4.738 5 5.162 5 5.923V7a1 1 0 0 0 1 1zm9.593 2.943c.584.585.584 1.53 0 2.116L12.71 15.95c-.39.39-1.03.39-1.42 0a.996.996 0 0 1 0-1.41 9.552 9.552 0 0 1 1.689-1.345l.387-.242-.207-.206a10 10 0 0 1-2.24.254H2.998a1 1 0 1 1 0-2h7.921a10 10 0 0 1 2.24.254l.207-.206-.386-.241a9.562 9.562 0 0 1-1.69-1.348.996.996 0 0 1 0-1.41c.39-.39 1.03-.39 1.42 0l2.883 2.893z" fill="#f18c20" /></svg>
            </Link>

            <div
              className="offcanvas offcanvas-start-xl bg-light"
              tabIndex={-1}
              id="offcanvasExample"
              aria-labelledby="offcanvasExampleLabel"
            >
              <div className="offcanvas-header d-flex d-xl-none">
                <h5
                  className="offcanvas-title text-white"
                  id="offcanvasExampleLabel"
                >
                  <Link
                    to="/"
                    className="navbar-brand d-flex align-items-center "
                  >
                    <img
                      src={require("../../assets/images/home/milliva-logo-text.png")}
                      style={{ width: "150px", height: "100%" }}
                      alt="logo"
                      className="img-fluid millilgo mx-2"
                    />
                  </Link>
                </h5>
                <a
                  href="#"
                  className="text-reset p-0"
                  data-bs-dismiss="offcanvas"
                  aria-label="close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="#FFFFFF"
                    className="bi bi-x-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </a>
              </div>

              <div className="offcanvas-body">
                <ul className="navbar-nav  me-auto mb-2 mt-1 mb-lg-0">
                  <li className="nav-item dropdown">
                    <span
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      id="navbarDropdown"
                      role="button"
                      aria-expanded="false"
                    >
                      For Trader
                    </span>

                    <div
                      className="dropdown-menu mainmenus"
                      aria-labelledby="navbarDropdown"
                    >
                      <>
                        <ul>
                          <li>
                            <a className="dropdown-item">Open Trade Account</a>
                          </li>
                          <li>
                            <span
                              onClick={() => handleRoute("account")}
                              className="dropdown-item"
                              data-bs-dismiss={"offcanvas"}
                            >
                              Account Type
                            </span>
                          </li>
                          <li className="open-demo-but">
                            <Link to="https://crm.milliva.com/client/register/6169896c79fd7">
                              <button
                                type="button"
                                className="btn btn-primary menu-orangebut"
                              >
                                Open Live Account
                              </button>
                            </Link>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <a className="dropdown-item" href="">
                              Analytics
                            </a>
                          </li>
                          <li>
                            <span
                              onClick={() => handleRoute("market")}
                              className="dropdown-item"
                              data-bs-dismiss={"offcanvas"}
                            >
                              Market Analytical
                            </span>
                          </li>
                          <li>
                            <span
                              onClick={() => handleRoute("ecnomic")}
                              className="dropdown-item"
                              data-bs-dismiss={"offcanvas"}
                            >
                              Economic calendar
                            </span>
                          </li>
                          <li>
                            <span
                              onClick={() => handleRoute("pipcalc")}
                              className="dropdown-item"
                              data-bs-dismiss={"offcanvas"}
                            >
                              Trader calculators
                            </span>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <a className="dropdown-item">Assets</a>
                          </li>
                          <li>
                            <span
                              onClick={() => handleRoute("currency")}
                              className="dropdown-item"
                              data-bs-dismiss={"offcanvas"}
                            >
                              Currency
                            </span>
                          </li>
                          {/* <li>
                            <span
                              onClick={() => handleRoute("commodities")}
                              className="dropdown-item"
                              data-bs-dismiss={"offcanvas"}
                            >
                              Commodities
                            </span>
                          </li> */}
                          {/* <li>
                            <span
                              onClick={() => handleRoute("indices")}
                              className="dropdown-item"
                              data-bs-dismiss={"offcanvas"}
                            >
                              Indices
                            </span>
                          </li> */}

                        </ul>
                        <ul>
                          <li>
                            <a className="dropdown-item">Fund</a>
                          </li>
                          <li>
                            <span
                              onClick={() => handleRoute("deposit")}
                              className="dropdown-item"
                              data-bs-dismiss="offcanvas"
                            >
                              Deposits and Withdrawals
                            </span>
                          </li>
                          <li>
                            <span
                              onClick={() => handleRoute("tradingfee")}
                              className="dropdown-item"
                              data-bs-dismiss="offcanvas"
                            >
                              Trading fees
                            </span>
                          </li>
                          <li>
                            <span
                              onClick={() => handleRoute("negativebb")}
                              className="dropdown-item"
                              data-bs-dismiss="offcanvas"
                            >
                              Negative balance
                            </span>
                          </li>
                          <li>
                            <span
                              onClick={() => handleRoute("segregate")}
                              className="dropdown-item"
                              data-bs-dismiss="offcanvas"
                            >
                              Segregate accounts
                            </span>
                          </li>
                        </ul>
                      </>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <span
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      id="navbarDropdown"
                      role="button"
                      aria-expanded="false"
                    >
                      For Beginner
                    </span>
                    <div
                      className="dropdown-menu mainmenus"
                      aria-labelledby="navbarDropdown"
                    >
                      <ul>
                        <li>
                          <a className="dropdown-item" href="">
                            Open Demo Account
                          </a>
                        </li>
                        <li className="open-demo-but">
                          <Link to="" className="dropdown-item">
                            <img
                              src={require("../../assets/images/home/mega-ico1.png")}
                              style={{
                                width: "100%",
                                height: "100%",
                                maxWidth: "118px",
                              }}
                              alt="logo"
                              className="img-fluid mx-2"
                            />
                          </Link>
                        </li>
                        <li className="open-demo-but">
                          <Link to="https://crm.milliva.com/opendemoaccount">
                            {" "}
                            <button
                              type="button"
                              className="btn btn-primary menu-orangebut"
                            >
                              Open Demo Account
                            </button>
                          </Link>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <a className="dropdown-item">Education</a>
                        </li>
                        <li>
                          <span
                            onClick={() => handleRoute("trading")}
                            className="dropdown-item"
                            data-bs-dismiss="offcanvas"
                          >
                            What is Trading
                          </span>
                        </li>

                        <li>
                          <span
                            onClick={() => handleRoute("faq")}
                            className="dropdown-item"
                            data-bs-dismiss="offcanvas"
                          >
                            FAQ
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => handleRoute("risk")}
                            className="dropdown-item"
                            data-bs-dismiss="offcanvas"
                          >
                            Risk management
                          </span>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <a className="dropdown-item" href="#">
                            Trading Platform
                          </a>
                        </li>
                        <li>
                          <span
                            onClick={() => handleRoute("mt5")}
                            className="dropdown-item"
                            data-bs-dismiss="offcanvas"
                          >
                            Metatrader-5
                          </span>
                        </li>
                        <li>
                          <Link
                            to="https://download.mql5.com/cdn/web/19448/mt5/milliva5setup.exe"
                            target="_blank"
                            className="dropdown-item"
                            download
                          >
                            MT- Desktop
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://download.mql5.com/cdn/mobile/mt5/ios?utm_source=www.metatrader5.com&utm_campaign=install.metaquotes
                            "
                            target="_blank"
                            className="dropdown-item"
                          >
                            MT- iPhone{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://download.mql5.com/cdn/mobile/mt5/android?utm_source=www.metatrader5.com&utm_campaign=install.metaquotes"
                            className="dropdown-item"
                            target="_blank"
                          >
                            {" "}
                            MT- Android{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to
                            href="https://download.mql5.com/cdn/web/19448/mt5/milliva5setup.exe"
                            className="dropdown-item"
                            download
                          >
                            MT- Mac{" "}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <span
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      id="navbarDropdown"
                      role="button"
                      aria-expanded="false"
                    >
                      For Partners
                    </span>
                    <div
                      className="dropdown-menu mainmenus"
                      aria-labelledby="navbarDropdown"
                    >
                      <ul>
                        <li className="open-demo-but">
                          <a className="dropdown-item" href="#">
                            <img
                              src={require("../../assets/images/home/mega-ico2.png")}
                              style={{
                                width: "100%",
                                height: "100%",
                                maxWidth: "300px",
                              }}
                              alt="logo"
                              className="img-fluid mx-2 partner-nav-img"
                            />
                          </a>
                        </li>
                      </ul>
                      <ul className="beginner-design-part">
                        <li>
                          <a className="dropdown-item" href="#" />
                        </li>
                        <li>
                          <span
                            onClick={() => handleRoute("introbroker")}
                            className="dropdown-item"
                            data-bs-dismiss="offcanvas"
                          >
                            Introducing broker
                          </span>
                        </li>
                        {/* <li>
                          <span
                            onClick={() => handleRoute("whitelabel")}
                            className="dropdown-item"
                            data-bs-dismiss="offcanvas"
                          >
                            White Label Solutions
                          </span>
                        </li> */}
                        <li>
                          <span
                            onClick={() => handleRoute("affiliate")}
                            className="dropdown-item"
                            data-bs-dismiss="offcanvas"
                          >
                            Affiliate Program
                          </span>
                        </li>
                      </ul>
                    </div>
                  </li>
                  {/* <li className="nav-item dropdown">
                    <span
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      id="navbarDropdown"
                      role="button"
                      aria-expanded="false"
                    >
                      Milliva Tools
                    </span>
                    <div
                      className="dropdown-menu mainmenus"
                      aria-labelledby="navbarDropdown"
                    >
                      <ul>
                        <li className="open-demo-but">
                          <a className="dropdown-item" href="#">
                            <img
                              src={require("../../assets/images/home/mega-ico3.png")}
                              style={{ width: "300px", height: "100%" }}
                              alt="logo"
                              className="img-fluid mx-2 partner-nav-img1"
                            />
                          </a>
                        </li>
                      </ul>
                      <ul className="beginner-design-part">
                        <li>
                          {/* <a className="dropdown-item" href="#" /> }
                        </li>
                        <li>
                          <span
                            onClick={() => handleRoute("pamm")}
                            className="dropdown-item"
                            data-bs-dismiss="offcanvas"
                          >
                            PAMM
                          </span>
                        </li>
                        {/* <li>
                          <span
                            onClick={() => handleRoute("mam")}
                            className="dropdown-item"
                            data-bs-dismiss="offcanvas"
                          >
                            MAM
                          </span>
                        </li> }
                        <li>
                          <span
                            onClick={() => handleRoute("algo")}
                            className="dropdown-item"
                            data-bs-dismiss="offcanvas"
                          >
                            Algo Trade
                          </span>
                        </li>
                        {/* <li>
                          <span
                            onClick={() => handleRoute("copytrade")}
                            className="dropdown-item"
                            data-bs-dismiss="offcanvas"
                          >
                            Copy Trade
                          </span>
                        </li> }
                      </ul>
                    </div>
                  </li> */}
                  {/* <li className="nav-item dropdown campi">
                    <span
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      id="navbarDropdown"
                      role="button"
                      aria-expanded="false"
                    >
                      <div className="pulse d-lg-none d-md-none">
                        <i class="fa fa-bell"></i>
                      </div> Campaigns

                    </span>


                    <div
                      className="dropdown-menu mainmenus"
                      aria-labelledby="navbarDropdown"
                    >
                      <ul className="campaign-des">
                        <li className="open-demo-but">
                          <a className="dropdown-item" href="#">
                            {" "}
                            <img
                              src={require("../../assets/images/home/mega-ico4.png")}
                              style={{ width: "300px", height: "100%" }}
                              alt="logo"
                              className="img-fluid mx-2 partner-nav-img2"
                            />
                          </a>
                        </li>
                      </ul>

                      <ul className="beginner-design">
                        {/* <li >
                          <a className="dropdown-item" href="#"></a>
                        </li> }

                        <li>
                          <span
                            onClick={() => handleRoute("loyal")}
                            className="dropdown-item"
                          // data-bs-dismiss="offcanvas"
                          >
                            Loyalty Program<span className="newmenu3 mx-3 blink">New</span>
                          </span>
                        </li>


                        <li>
                          <span
                            onClick={() => handleRoute("bonus")}
                            className="dropdown-item"
                          // data-bs-dismiss="offcanvas"
                          >
                            Bonus
                          </span>
                        </li>
                        <li >
                          <span
                            onClick={() => handleRoute("promotions")}
                            className="dropdown-item"
                          // data-bs-dismiss="offcanvas" 

                          >
                            Promotions and contests
                          </span>


                        </li>
                        {/* <li>
                          <span
                            onClick={() => handleRoute("iboffer")}
                            className="dropdown-item"
                          // data-bs-dismiss="offcanvas"
                          >
                            IB Campaigns<span className="newmenu mx-3">Sold</span>
                          </span>
                        </li> }

                        <li>
                          <span
                            onClick={() => handleRoute("ibpromotion")}
                            className="dropdown-item"
                          // data-bs-dismiss="offcanvas"
                          >
                            IB Promotions<span className="newmenu mx-3">Sold</span>
                          </span>
                        </li>


                      </ul>
                    </div>
                  </li> */}
                  <li className="nav-item dropdown">
                    <span
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      id="navbarDropdown"
                      role="button"
                      aria-expanded="false"
                    >
                      Client Support
                    </span>

                    <div
                      className="dropdown-menu mainmenus"
                      aria-labelledby="navbarDropdown"
                    >
                      <ul className="campaign-des">
                        <li className="open-demo-but">
                          <a className="dropdown-item" href="#">
                            <img
                              src={require("../../assets/images/home/mega-ico5.png")}
                              style={{ width: "300px", height: "100%" }}
                              alt="logo"
                              className="img-fluid mx-2 partner-nav-img3"
                            />
                          </a>
                        </li>
                      </ul>

                      <ul className="beginner-design">
                        <li>
                          <a className="dropdown-item" href="#"></a>
                        </li>
                        <li>
                          <span
                            onClick={() => handleRoute("about")}
                            className="dropdown-item"
                            data-bs-dismiss="offcanvas"
                          >
                            About us
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => handleRoute("whychoose")}
                            className="dropdown-item"
                            data-bs-dismiss="offcanvas"
                          >
                            Why choose us
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => handleRoute("contact")}
                            className="dropdown-item"
                            data-bs-dismiss="offcanvas"
                          >
                            Contact us
                          </span>
                        </li>

                        <li>
                          <span
                            onClick={() => handleRoute("legal")}
                            className="dropdown-item"
                            data-bs-dismiss="offcanvas"
                          >
                            Regulations and License
                          </span>
                        </li>
                      </ul>
                    </div>
                  </li>
                  {/* <li className="nav-item dropdown">
                    <span
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      id="navbarDropdown"
                      role="button"
                      aria-expanded="false"
                      
                    >
                      Products
                    </span>
                    <div
                      className="dropdown-menu mainmenus"
                      aria-labelledby="navbarDropdown"
                    >
                      <ul className="d-flex align-items-center">
                        <li className="open-demo-but">
                          <a className="dropdown-item" href="#">
                            <img
                              src={require("../../assets/images/webdevelopment.png")}
                              style={{
                                width: "100%",
                                height: "100%",
                                maxWidth: "300px",
                              }}
                              alt="logo"
                              className="img-fluid mx-2 partner-nav-img"
                            />
                          </a>
                        </li>
                      </ul>
                      <ul className="beginner-design-part">
                        <li>
                          <a className="dropdown-item" href="#" />
                        </li>
                        <li>
                          <span
                            onClick={() => {
                              currencypage("products");
                            }}
                            className="dropdown-item"
                            data-bs-dismiss="offcanvas"
                            
                          >
                            Products
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => {
                              currencypage("refund");
                            }}
                            className="dropdown-item"
                            data-bs-dismiss="offcanvas"
                          >
                            Return and Refund Policy
                          </span>
                        </li>

                      </ul>
                    </div>
                  </li> */}
                </ul>
                <div className="offcanvas2">
                  <ul className="navbar-nav ms-auto mb-2 mt-1 mb-lg-0 d-flex">
                    <li className="nav-item">
                      <Link
                        to="https://crm.milliva.com/client/register/6169896c79fd7"
                        className="nav-link opacc"
                      // aria-current="page" onClick={handleShow1}
                      >
                        Open Account
                      </Link>
                      <div className="sidebaroffcanvas">
                        <Offcanvas show={show1} onHide={handleClose1} className="sidebaroffcanvas" placement="end">
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Register Account</Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <div className="millilogin">
                              <form>
                                <div className="cltname mb-3">
                                  <label htmlFor="exampleInputName" className="form-label">Username</label>
                                  <input type="text" className="form-control" id="exampleInputName" aria-describedby="emailHelp" placeholder="Enter Your Email Address" />

                                </div>
                                <div className="mb-3">
                                  <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                  <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />

                                </div>
                                <div className="col-md-12 col-lg-12 mb-3">
                                  <div className="row">
                                    <div className="col-md-12 col-lg-4">
                                      <label htmlFor="exampleInputName" className="form-label">Country</label>
                                      <select class="form-select" aria-label="Default select example">
                                        <option selected>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                    <div className="col-md-12 col-lg-8">
                                      <div className="">
                                        <label htmlFor="exampleInputNumber" className="form-label">Phone Number</label>
                                        <input type="number" className="form-control" id="exampleInputNumber" aria-describedby="emailHelp" />

                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="cltname mb-3">
                                  <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                  <input type="password" className="form-control mb-2" id="exampleInputPassword1" placeholder="Enter Your Password" />
                                  <small>Note: Do not share your password to anyone.</small>
                                </div>
                                <div className="referclt mb-3">
                                  <a class="" onClick={() => {
                                    if (showdata2 === true) {
                                      setShowdata2(false)
                                    } else {
                                      setShowdata2(true)
                                    }
                                  }}>  <label className='form-label fs-6'> Referral (Optional) </label>
                                    <span className=" text-primary" id="inputGroupPrepend" style={{ cursor: "pointer" }} >
                                      <i className='fa fa-angle-down' style={{ fontSize: "20px", float: "right" }} ></i>
                                    </span></a>
                                  {showdata2 ?
                                    <div className=''>
                                      <input type="text" className='form-control mb-3 mt-0' placeholder="Enter your Referral Code" />

                                    </div> : ""}
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                                  <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Terms and Conditions
                                  </label>
                                </div>
                              </form>
                              <div className="millibtn mt-3 mb-3">

                                <button type="button" class="btn">Register Now</button>
                              </div>
                              <div className="d-flex justify-content-start millinlinks">
                                <div className="fogot">
                                  <a href="#">Already Account?</a>
                                </div>

                              </div>



                            </div>
                          </Offcanvas.Body>
                        </Offcanvas>
                      </div>
                    </li>
                    <li className="nav-item hhhh">
                      <Link
                        to="https://crm.milliva.com/login"
                        className="nav-link signin"
                      // aria-current="page" onClick={handleShow}
                      >
                        Sign in
                      </Link>
                      <div className="sidebaroffcanvas">
                        <Offcanvas show={show} onHide={handleClose} className="sidebaroffcanvas" placement="end">
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Account Login</Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <div className="millilogin">
                              <form>
                                <div className="cltname mb-3">
                                  <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                  <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your Email Address" />

                                </div>
                                <div className="cltname mb-3">
                                  <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                  <input type="password" className="form-control mb-2" id="exampleInputPassword1" placeholder="Enter Your Password" />
                                  <small>Note: Do not share your password to anyone.</small>
                                </div>
                              </form>
                              <div className="millibtn mt-3 mb-3">

                                <button type="button" class="btn">Login</button>
                              </div>
                              <div className="d-flex justify-content-between millinlinks">
                                <div className="fogot">
                                  <a href="#">Resend ActivationMail?</a>
                                </div>
                                <div className="fogot">

                                  <Link to="/forgot">ForgotPassword</Link>
                                </div>
                              </div>
                              <div className="millireg mt-3 mb-3">

                                <button type="button" class="btn">Register</button>
                              </div>

                            </div>
                          </Offcanvas.Body>
                        </Offcanvas>
                      </div>


                    </li>
                    {/* <li className="nav-item dropdown notifyy">
                      <span
                        className="nav-link dropdown-toggle"
                        data-bs-toggle="dropdown"
                        id="navbarDropdown"
                        role="button"
                        aria-expanded="false"
                      >
                        <svg fill="#000000" width="25" height="25" viewBox="0 0 32 32" id="Outlined" >

                          <title />

                          <g id="Fill">

                            <path d="M30,24a6,6,0,0,1-6-6V14a8,8,0,0,0-5.39-7.55A3,3,0,0,0,19,5a3,3,0,0,0-6,0,3,3,0,0,0,.39,1.45A8,8,0,0,0,8,14v4a6,6,0,0,1-6,6v2h9.1A5,5,0,0,0,16,30a4.67,4.67,0,0,0,4.91-4H30ZM15,5a1,1,0,1,1,1,1A1,1,0,0,1,15,5Zm1,23a3,3,0,0,1-2.82-2h5.68A2.7,2.7,0,0,1,16,28ZM7.29,24A8,8,0,0,0,10,18V14a6,6,0,0,1,12,0v4a8,8,0,0,0,2.71,6Z" />

                          </g>

                        </svg>
                        <span class="icon-button__badge"></span>
                      </span>

                      <div
                        className="dropdown-menu notimenu"
                        aria-labelledby="navbarDropdown"
                      >

                        <div className="nottitle d-flex justify-content-between">
                          <div className="notificat">
                            <h6>Notifications</h6>
                          </div>
                          <div className="showmore">
                            <h6>Showmore</h6>
                          </div>
                        </div>
                        <div className="ltnews mt-3">
                          <h6><svg fill="#f18c20" className="mx-2" width="20" height="20" viewBox="0 0 24 24" id="Outline" xmlns="http://www.w3.org/2000/svg"><title>194 restore</title>
                            <path d="M12,6a1,1,0,0,0-1,1v5a1,1,0,0,0,.293.707l3,3a1,1,0,0,0,1.414-1.414L13,11.586V7A1,1,0,0,0,12,6Z
M23.812,10.132A12,12,0,0,0,3.578,3.415V1a1,1,0,0,0-2,0V5a2,2,0,0,0,2,2h4a1,1,0,0,0,0-2H4.827a9.99,9.99,0,1,1-2.835,7.878A.982.982,0,0,0,1,12a1.007,1.007,0,0,0-1,1.1,12,12,0,1,0,23.808-2.969Z"/></svg>Latest News</h6>
                        </div>
                        <a href="#" className="d-flex justify-content-between updatenoti mt-3">
                          <div className="notimg">
                            <img
                              src={require("../../assets/images/home/blogimg1.jpg")}
                              style={{ width: "100%", maxWidth: "200px", height: "100%" }}
                              alt="logo"
                              className="img-fluid mx-2"
                            />
                          </div>
                          <div className="notcont">
                            <h6>Recognizing IBs / Sub-broker’s Hard Work With Rewards</h6>
                            <span>June15,2023</span>
                          </div>
                        </a>
                        <a href="#" className="d-flex justify-content-between updatenoti mt-3">
                          <div className="notimg">
                            <img
                              src={require("../../assets/images/home/blogimg1.jpg")}
                              style={{ width: "100%", maxWidth: "200px", height: "100%" }}
                              alt="logo"
                              className="img-fluid mx-2"
                            />
                          </div>
                          <div className="notcont">
                            <h6>Recognizing IBs / Sub-broker’s Hard Work With Rewards</h6>
                            <span>June15,2023</span>
                          </div>
                        </a>
                        <a href="#" className="d-flex justify-content-between updatenoti mt-3">
                          <div className="notimg">
                            <img
                              src={require("../../assets/images/home/blogimg1.jpg")}
                              style={{ width: "100%", maxWidth: "200px", height: "100%" }}
                              alt="logo"
                              className="img-fluid mx-2"
                            />
                          </div>
                          <div className="notcont">
                            <h6>Recognizing IBs / Sub-broker’s Hard Work With Rewards</h6>
                            <span>June15,2023</span>
                          </div>
                        </a>

                      </div>
                    </li> */}

                  </ul>

                </div>

                {/* <script type="text/javascript" defer={true} src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>

                <div className="offcanvas2 m-2">
                  <select class="form-select form-select-sm" value={inputFormat}
                    onChange={(e) => { handleLang(e.target.value); handlelangChange() }}>
                    {Object.keys(coutryList).map((key, index) => {
                      const language = coutryList[key];
                      return (
                        <option key={index} value={key}>{language.name}</option>
                      );
                    })}
                  </select>
                </div> */}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>

  );
};

export default Header;
