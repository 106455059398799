import React, { useState, useEffect } from "react";
import "../../assets/css/style.css";
import { useForm } from "react-hook-form";
import { Modal, Form, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Subscribe } from "../Helper/Api";
import { VALIDATION_PATTERN } from "../Signin/Store";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import tuittwrimg from "../../assets/images/twitter-x.png"
import { FaXTwitter } from "react-icons/fa6";
const Footer = () => {

  const location = useLocation()


  const currency = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    reset: reset1,
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset: reset2,
  } = useForm();

  const [show, setShow] = React.useState(false);
  const [show1, setShow1] = React.useState(false);
  const [show2, setShow2] = React.useState(false);

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleShow2 = () => setShow2(true);

  const handleClose2 = () => {
    setShow2(false);
    reset2();
  };
  const handleClose1 = () => {
    setShow1(false);
    reset1();
  };
  const handleClose = () => {
    setShow(false);
    reset();
  };

  const Submit = (data) => {
    let formdata = {
      ...data,
    };
    setLoading(true);

    Subscribe(formdata)
      .then((res) => {
        if (res.data.status == 1) {
          setLoading(false);
          NotificationManager.success(res.data.message, "Success");
          window.open("https://www.milliva.com/uploads/legal-documents.pdf");

          handleClose();
        } else if (res.data.status == 0) {
          NotificationManager.error(res.data.message, "Failed");

          setLoading(false);
          handleClose();
        } else {
          NotificationManager.error("Backend problem", "Failed");

          setLoading(false);
          handleClose();
        }
      })
      .catch((err) => {
        setLoading(false);
        handleClose();

        NotificationManager.error("Some Error Occured", "Failed");
      });
  };

  const Submit1 = (data) => {
    let formdata = {
      ...data,
    };
    setLoading1(true);

    Subscribe(formdata)
      .then((res) => {
        if (res.data.status == 1) {
          setLoading1(false);
          NotificationManager.success(res.data.message, "Success");
          window.open("https://www.milliva.com/uploads/legal-documents.pdf");

          handleClose();
        } else if (res.data.status == 0) {
          NotificationManager.error(res.data.message, "Failed");

          setLoading1(false);
          handleClose1();
        } else {
          NotificationManager.error(res.data.message, "Failed");

          setLoading(false);
          handleClose1();
        }
      })
      .catch((err) => {
        setLoading1(false);
        handleClose1();

        NotificationManager.error("Some Error Occured", "Failed");
      });
  };

  const Submit2 = (data) => {
    let formdata = {
      ...data,
    };
    setLoading2(true);

    Subscribe(formdata)
      .then((res) => {
        if (res.data.status == 1) {
          setLoading2(false);
          NotificationManager.success(res.data.message, "Success");
          window.open("https://www.milliva.com/uploads/legal-documents.pdf");

          handleClose2();
        } else if (res.data.status == 0) {
          NotificationManager.error(res.data.message, "Failed");

          setLoading2(false);
          handleClose2();
        } else {
          NotificationManager.error(res.data.message, "Failed");

          setLoading2(false);
          handleClose2();
        }
      })
      .catch((err) => {
        setLoading2(false);
        handleClose2();

        NotificationManager.error("Some Error Occured", "Failed");
      });
  };

  const currencypage = (data) => {
    currency(`/${data}`);
    setTimeout(() => {
      window.scroll({
        top: 0,

        behavior: "smooth",
      });
    }, 100);
  };


  return (
    <div>
      {/* <Helmet>
        <script>
          {`
          var chatbot_id=15959;
          !function(){var t,e,a=document,s="smatbot-chatbot";
          a.getElementById(s)||(t=a.createElement("script"),t.id=s,t.type="text/javascript",t.src="https://smatbot.s3.amazonaws.com/files/smatbot_plugin.js.gz",e=a.getElementsByTagName("script")[0],e.parentNode.insertBefore(t,e))}();
          `}
        </script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/fingerprintjs2/1.5.1/fingerprint2.min.js" />
      </Helmet> */}
      <NotificationContainer />
      <div className="legalmodal">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="border-0">
            <Modal.Title>Subscription form</Modal.Title>
            <Button variant="" className="modalx" onClick={handleClose}>
              X{" "}
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="email"
                  placeholder="Enter your email address"
                  style={{ marginTop: "-14px" }}
                  autocomplete="off"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                    pattern: {
                      value: VALIDATION_PATTERN.EMAIL,
                      message: "Enter Valid Email",
                    },
                  })}
                />
                <p className="text-danger">
                  {errors.email && <span role="alert">Email is required</span>}
                </p>
              </Form.Group>
            </Form>
            {loading ? (
              <>
                {" "}
                <button type="buttton" className="btn legalbtn m-auto d-block">
                  Loading...
                </button>
              </>
            ) : (
              <>
                {" "}
                <button
                  type="submit"
                  className="btn legalbtn m-auto d-block"
                  onClick={handleSubmit(Submit)}
                >
                  Subscribe Now
                </button>
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>
      <div className="legalmodal">
        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="border-0">
            <Modal.Title>Subscription form</Modal.Title>
            <Button variant="" className="modalx" onClick={handleClose1}>
              X{" "}
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="email"
                  placeholder="Enter your email address"
                  style={{ marginTop: "-14px" }}
                  autocomplete="off"
                  {...register1("email", {
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                    pattern: {
                      value: VALIDATION_PATTERN.EMAIL,
                      message: "Enter Valid Email",
                    },
                  })}
                />
                <p className="text-danger">
                  {errors1.email && <span role="alert">Email is required</span>}
                </p>
              </Form.Group>
            </Form>
            {loading1 ? (
              <>
                {" "}
                <button type="buttton" className="btn legalbtn m-auto d-block">
                  Loading...
                </button>
              </>
            ) : (
              <>
                {" "}
                <button
                  type="submit"
                  className="btn legalbtn m-auto d-block"
                  onClick={handleSubmit1(Submit1)}
                >
                  Subscribe Now
                </button>
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>
      <div className="legalmodal">
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="border-0">
            <Modal.Title>Subscription form</Modal.Title>
            <Button variant="" className="modalx" onClick={handleClose2}>
              X{" "}
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="email"
                  placeholder="Enter your email address"
                  style={{ marginTop: "-14px" }}
                  autocomplete="off"
                  {...register2("email", {
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                    pattern: {
                      value: VALIDATION_PATTERN.EMAIL,
                      message: "Enter Valid Email",
                    },
                  })}
                />
                <p className="text-danger">
                  {errors2.email && <span role="alert">Email is required</span>}
                </p>
              </Form.Group>
            </Form>
            {loading2 ? (
              <>
                {" "}
                <button type="buttton" className="btn legalbtn m-auto d-block">
                  Loading...
                </button>
              </>
            ) : (
              <>
                {" "}
                <button
                  type="submit"
                  className="btn legalbtn m-auto d-block"
                  onClick={handleSubmit2(Submit2)}
                >
                  Subscribe Now
                </button>
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>

      <div className="footer-logo footer-info">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Link to="/" className="navbar-brand d-flex align-items-center ">
                <img
                  src={require("../../assets/images/home/milliva-logo-text.png")}
                  style={{ width: "150px", height: "100%" }}
                  alt="logo"
                  className="img-fluid mx-2"
                />
                <span className="milli"></span>
              </Link>
              <h5 className="px-3 mt-2">
                Get An Outstanding Trading Experience With Milliva LTD!
              </h5>

              <div className="social-links mt-3">
                <a href="#" className="twitter">
                  <i className="bi bi-twitter" />
                </a>
                <a href="#" className="facebook">
                  <i className="bi bi-facebook" />
                </a>
                <a href="#" className="instagram">
                  <i className="bi bi-instagram" />
                </a>
                <a href="#" className="linkedin">
                  <i className="bi bi-linkedin" />
                </a>
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div className="social-home">
                <h5 className="mx-5 mb-3">Get in Touch With Us </h5>
                <ul className="d-flex">
                  <li>
                    <a href="https://www.facebook.com/Millivaforex">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li>
                    <a href="https://mobile.twitter.com/milliva_fx/with_replies" className="twitter-x-footer">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                      <i className="twitter"><FaXTwitter /></i>
                    </a>{" "}
                  </li>
                  <li>
                    <a href="https://www.instagram.com/millivafx/">
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li>
                    <a href="https://t.me/MILLIVAFX">
                      <i className="fa fa-telegram" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li>
                    <a href="https://in.pinterest.com/millivam/">
                      <i className="fa fa-pinterest" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UCdd_R2Jinyr018UOZej899g">
                      <i className="fa fa-youtube-play" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <footer id="footer" className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row gy-4">
              {/* <div className="col-lg-2 col-6 footer-links">
                <Link to="/assets">
                  {" "}
                  <h4>Assets</h4>
                </Link>
                <ul>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("currency");
                      }}
                    >
                      Currency
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("commodities");
                      }}
                    >
                      Commodities
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("indices");
                      }}
                    >
                      Indices
                    </span>
                  </li>
               
                </ul>
              </div> */}
              <div className="col-lg-2 col-6 footer-links">
                <h4>Fund</h4>
                <ul>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("Deposit");
                      }}
                    >
                      Deposits and Withdrawals
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("tradingfee");
                      }}
                    >
                      Trading fees
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("negativebb");
                      }}
                    >
                      Negative balance
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("segregate");
                      }}
                    >
                      Segregate accounts
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-6 footer-links">
                <h4>Trading Platform</h4>
                <ul>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("mt5");
                      }}
                    >
                      Metatrader-5
                    </span>
                  </li>
                  <a
                    className="dropdown-item"
                    href="https://download.mql5.com/cdn/web/19448/mt5/milliva5setup.exe"
                    download
                  >
                    <li>
                      <i className="bi bi-chevron-right" /> MT- Desktop
                    </li>
                  </a>
                  <a
                    className="dropdown-item"
                    href="https://download.mql5.com/cdn/mobile/mt5/ios?utm_source=www.metatrader5.com&utm_campaign=install.metaquotes
                            "
                  >
                    <li>
                      <i className="bi bi-chevron-right" /> MT- iPhone
                    </li>
                  </a>
                  <a
                    href="https://download.mql5.com/cdn/mobile/mt5/android?utm_source=www.metatrader5.com&utm_campaign=install.metaquotes"
                    className="dropdown-item"
                  // onClick={handleCaptureClick}
                  >
                    <li>
                      <i className="bi bi-chevron-right" /> MT- Android
                    </li>
                  </a>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://download.mql5.com/cdn/web/19448/mt5/milliva5setup.exe"
                      download
                    >
                      <i className="bi bi-chevron-right" /> MT- Mac
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 col-6 footer-links">
                <h4>For Partners</h4>
                <ul>

                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("introbroker");
                      }}
                    >
                      {" "}
                      Introducing broker
                    </span>
                  </li>

                  {/* <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("Whitelabel");
                      }}
                    >
                      {" "}
                      White Label Solutions
                    </span>
                  </li> */}
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("affiliate");
                      }}
                    >
                      {" "}
                      Affiliate Program
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("products");
                      }}
                    >
                      Products
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("refund");
                      }}
                    >
                      Privacy
                    </span>
                  </li>
                </ul>
              </div>

              {/* <div className="col-lg-2 col-6 footer-links">
                <h4>Milliva Tools</h4>
                <ul>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("Pamm");
                      }}
                    >
                      PAMM
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("Mam");
                      }}
                    >
                      MAMM
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("Algo");
                      }}
                    >
                      {" "}
                      Algo Trade
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("Copytrade");
                      }}
                    >
                      {" "}
                      Copy Trade
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("risk");
                      }}
                    >
                      {" "}
                      Risk Management
                    </span>
                  </li>
                </ul>
              </div> */}

              <div className="col-lg-2 col-6 footer-links">
                <h4>Client Support</h4>
                <ul>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("about");
                      }}
                    >
                      About us
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("whychoose");
                      }}
                    >
                      {" "}
                      Why choose us
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("contact");
                      }}
                    >
                      {" "}
                      Contact us
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("faq");
                      }}
                    >
                      FAQ
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />
                    <span
                      className="handleput"
                      onClick={() => {
                        currencypage("legal");
                      }}
                    >
                      Regulations and License
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-6 footer-links">
                <h4>Our Country Support</h4>
                <div className="row">
                  <div className="col-6 px-2">
                    <ul>
                      <li>
                        <i className="bi bi-chevron-right" />{" "}
                        <span
                          className="handleput"
                          onClick={() => {
                            currencypage('best-forex-brokers-in-hong-kong');
                          }}
                        >
                          Hong Kong
                        </span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right" />{" "}
                        <span
                          className="handleput"
                          onClick={() => {
                            currencypage("best-forex-brokers-in-china");
                          }}
                        >
                          {" "}
                          China
                        </span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right" />{" "}
                        <span
                          className="handleput"
                          onClick={() => {
                            currencypage("best-forex-brokers-in-uae");
                          }}
                        >
                          {" "}
                          UAE
                        </span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right" />
                        <span
                          className="handleput"
                          onClick={() => {
                            currencypage("best-forex-brokers-in-malaysia");
                          }}
                        >
                          Malaysia
                        </span>
                      </li>
                    </ul>

                  </div>
                  <div className="col-6 px-2">
                    <ul>
                      <li>
                        <i className="bi bi-chevron-right" />
                        <span
                          className="handleput"
                          onClick={() => {
                            currencypage("best-forex-brokers-in-singapore");
                          }}
                        >
                          Singapore
                        </span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right" />
                        <span
                          className="handleput"
                          onClick={() => {
                            currencypage("best-forex-brokers-in-canada");
                          }}
                        >
                          Canada
                        </span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right" />
                        <span
                          className="handleput"
                          onClick={() => {
                            currencypage("best-forex-brokers-in-usa");
                          }}
                        >
                          USA
                        </span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right" />
                        <span
                          className="handleput"
                          onClick={() => {
                            currencypage("best-forex-brokers-in-india");
                          }}
                        >
                          India
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>


            <div className="footer-bottom">
              <ul>
                <li
                  style={{ cursor: "pointer", color: "#f09433" }}
                >
                  <a href={require("../../assets/documents/legal-documents.pdf")}
                    type="" target="_blank"
                    className=""
                    style={{ cursor: "pointer", color: "#f09433" }}
                  >
                    Legal Documents
                  </a>
                </li>
                <li>|</li>
                <li
                  onClick={handleShow}
                  style={{ cursor: "pointer", color: "#f09433" }}
                >
                  Privacy Policy{" "}
                </li>
                <li>|</li>
                <li
                  onClick={handleShow1}
                  style={{ cursor: "pointer", color: "#f09433" }}
                >
                  Terms and Conditions
                </li>
                <li>|</li>
                <li
                  style={{ cursor: "pointer", color: "#f09433" }}
                >
                  <a href={require("../../assets/documents/arbitage-terms-and-condition (1).pdf")}
                    type="" target="_blank"
                    className=""
                    style={{ cursor: "pointer", color: "#f09433" }}
                  >
                    Arbitrage Policy
                  </a>


                </li>
                <li>|</li>
                <li
                  style={{ cursor: "pointer", color: "#f09433" }}
                >
                  <a href={require("../../assets/documents/cookies-policy.pdf")}
                    type="" target="_blank"
                    className=""
                    style={{ cursor: "pointer", color: "#f09433" }}
                  >
                    Cookies Policy
                  </a>
                </li>
                <li>|</li>

                <li
                  style={{ cursor: "pointer", color: "#f09433" }}
                >
                  <a href={require("../../assets/documents/AML Policy.pdf")}
                    type="" target="blank"
                    className=""
                    style={{ cursor: "pointer", color: "#f09433" }}
                  >
                    AML Policy
                  </a>


                </li>
                <li>|</li>
                <li
                  style={{ cursor: "pointer", color: "#f09433" }}
                >
                  <a href={require("../../assets/documents/Risk diclosure.pdf")}
                    type="" target="_blank"
                    className=""
                    style={{ cursor: "pointer", color: "#f09433" }}
                  >
                    Risk Disclosure
                  </a>


                </li>
                <li>|</li>
                {/* <li
                  style={{ cursor: "pointer", color: "#f09433" }}
                >
                  <a href={require("../../assets/documents/pamm-agreement.pdf")}
                    type="" target="blank"
                    className=""
                    style={{ cursor: "pointer", color: "#f09433" }}
                  >
                    PAMM Policy
                  </a>


                </li> */}
                {/* <li>|</li> */}
                <li
                  style={{ cursor: "pointer", color: "#f09433" }}
                >
                  <a href={require("../../assets/documents/Ipoa.pdf")}
                    type="" target="_blank"
                    className=""
                    style={{ cursor: "pointer", color: "#f09433" }}
                  >
                    LPOA
                  </a>


                </li>
              </ul>
              <div className="risk-footer">
                <p className="fs-6">
                  <span className="fw-bold">
                    Legal Documents:{" "}
                  </span>
                  Milliva Ltd is proud to be regulated by the  Comoros. License Number:<b className="p-1">L15696/MLTD</b>
                </p>
                {/* <p>
                  <span className="fw-bold">Milliva Ltd</span> [www.milliva.com]
                  is registered in England and U.K Company House with company
                  Incorporation number 13546193. Milliva LTD Trade name of Milliva
                  Ltd UK, which acts as a Financial Registered company providing
                  Trading Solutions for International Ltd. Milliva Ltd is
                  Registered at (Financial Services Authority) FSA under Saint
                  Vincent and the Grenadines, Registered number: 26892 BC 2022,
                  and very soon, we will establish a Security license.
                </p> */}
                <p>
                  <span className="fw-bold">Risk Warning : </span>Trading
                  leveraged products like forex and derivatives may not suit all
                  investors due to the high risk of losing money. Before
                  trading, please ensure you fully understand the risks
                  involved, considering your investment objectives and degree of
                  expertise, and get independent advice if necessary. seek
                  independent advice or review our legal documents for further
                  information.
                </p>
                <p>
                  <span className="fw-bold">
                    Risk Disclosure for Restricted Countries:{" "}
                  </span>
                  Regional restrictions Milliva Ltd does not provide
                  services to residents of the USA,  Japan, Canada,
                  Haiti, Iran, Suriname, the Democratic People's Republic of
                  Korea, Puerto Rico, Brazil, the Occupied Area of Cyprus,
                  Quebec, Iraq, Syria and Cuba, and Milliva Ltd request the
                  clients to check your country jurisdiction.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright">
            Copyright © 2020-2024
            <strong>
              <span className="mx-2">milliva.com</span>
            </strong>
            . All Rights Reserved
            <a href="#top" className="topbtn">
              <BsFillArrowUpCircleFill />
            </a>
            {/* <a href="https://api.whatsapp.com/send?phone=971525754887" className="whatsupico" > */}
            <a href="https://wa.link/yldrvr" className="whatsupico" >

              <img
                src={require("../../assets/images/home/whatsupicon.png")}

                alt="logo"
                className="img-fluid"
              />
            </a>

            {/* {location.pathname === "/loyal" ? "" : <Link to="/loyal" className="blink1">
              <button className="main-button">
                <span> Loyalty Program</span></button>
            </Link>} */}
          </div>
          <div className="credits">
            {/* All the links in the footer should remain intact. */}
            {/* You can delete the links only if you purchased the pro version. */}
            {/* Licensing information: https://bootstrapmade.com/license/ */}
            {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/flexstart-bootstrap-startup-template/ */}
          </div>
        </div>

        {/* <div><iframe class="m-w-100" src="https://www.smatbot.com/kya_backend/share?code=Milliva_Ltd_15959" style={{border:'0',width:'400',height:'500'}} ></iframe></div> */}
        {/* <div><iframe class="m-w-100 chat_box" src="https://www.smatbot.com/kya_backend/share?code=Milliva_Ltd_15959" style={{border:'0',width:'500px',height:'auto', position:'fixed', bottom:'0', zIndex:'999999', right:'2%', minHeight:'504px'}} ></iframe></div> */}

      </footer>
    </div>
  );
};

export default Footer;
