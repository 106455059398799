import React, { useEffect, lazy, Suspense ,useState} from "react";
import { useParams } from "react-router-dom";
import "../../assets/css/aboutus.css";
import axios from 'axios';
import Loader from "../Loader/Loader";
const HongKong = lazy(() => import("./HongKong"))
const China = lazy(() => import("./China"))
const India = lazy(() => import("./India"))
const Singapore = lazy(() => import("./Singapore"))
const UAE = lazy(() => import("./UAE"))
const Malaysia = lazy(() => import("./Malaysia"))
const Canada = lazy(() => import("./Canada"))
const USA = lazy(() => import("./USA"))



const CountryPage = () => {
    let { country } = useParams();

    const [countryApi, setcountryApi] = useState('IN');

    useEffect(() => {
        setTimeout(() => {
            window.scroll({
                top: 0,

                behavior: "smooth",
            });
        }, 100);
    }, []);

    useEffect(() => {
        const fetchCountry = async () => {
            try {
                const response = await axios.get('https://ipinfo.io?token=7bb1a2a17f6376');
                if (response) {
                    setcountryApi(response?.data?.country);
                } else {
                    setcountryApi('IN');
                }

            } catch (error) {
                console.log(error);
            }
        };

        fetchCountry();
    }, []);
    return (
        <Suspense fallback={<Loader />}>
            <div className="country-list">
                {(country == 'best-forex-brokers-in-hong-kong') && <HongKong url={country} />}
                {(country == 'best-forex-brokers-in-china')&& <China url={country} />}
                {(country == 'best-forex-brokers-in-uae') && <UAE url={country} />}
                {(country == 'best-forex-brokers-in-malaysia') && <Malaysia url={country} />}
                {(country == 'best-forex-brokers-in-singapore') && <Singapore url={country} />}
                {(country == 'best-forex-brokers-in-usa') && <USA url={country} />}
                {(country == 'best-forex-brokers-in-canada') && <Canada url={country} />}
                {(country == 'best-forex-brokers-in-india') && <India url={country} />}
            </div>
        </Suspense>

    );

}

export default CountryPage;